import { ref } from "vue";
import { useStore } from "vuex";
import numeral from "numeral";

export default function () {
  const tin = ref("");
  const year = ref(new Date().getFullYear());
  const store = useStore();
  let deductions = ref([]);
  let incomes = ref([]);
  const pitDetails = ref({
    tin: "",
    assessment_year: new Date().getFullYear(),
    income: 0,
    bim_levy: {},
    num_of_months: 12,
    bus_prem: true,
    saveRecord: false,
  });

  //methods
  const formatAmount = (amt) => {
    return numeral(amt).format("0,0.00");
  };
  const addToIncomes = (income) => {
    store.dispatch("assessment/addPayeIncome", income);
  };
  const removeIncome = (i) => {
    store.dispatch("assessment/removePayeIncome", i);
  };
  const addToDeductions = (deduction) => {
    store.dispatch("assessment/addPayeDeduction", deduction);
  };
  const removeDeduction = (i) => {
    store.dispatch("assessment/removePayeDeduction", i);
  };
  const resetPayeAssessment = () => {
    // store.dispatch("assessment/addPayeIncomes", []);
    // store.dispatch("assessment/addPayeDeductions", []);
    store.dispatch("assessment/resetPayeAssessmentValues");
  };
  const saveAssessment = () => {
    // console.log(pitDetails.value);
  };

  return {
    tin,
    year,
    deductions,
    incomes,
    pitDetails,
    formatAmount,
    addToIncomes,
    removeIncome,
    addToDeductions,
    removeDeduction,
    saveAssessment,
    resetPayeAssessment,

    // submitPayeAssessment
  };
}
