<template>
  <div class="login h-screen overflow-y-hidden">
    <div
      class="wrapper w-full h-full auto-padding-top overflow-y-auto hide-scrollbar md:show-scrollbar"
    >
      <div
        class="w-full bg-white sm:shadow-2xl sm:w-80 mx-auto flex flex-col rounded-md p-5 sm:justify-center sm:items-center"
      >
        <div v-if="!resetPasswordState" class="mb-8 w-full text-left">
          <h3 class="text-gray-600 font-semibold text-2xl">Welcome back,</h3>
          <h6 class="text-gray-400 font-medium">Sign in to continue</h6>
        </div>

        <form v-if="!resetPasswordState" action="/login">
          <div class="form-input flex flex-col w-full">
            <p
              v-if="error.state"
              class="transition text-red-500 font-medium text-sm w-full mb-2 bg-red-200 px-4 py-2 rounded-md"
            >
              {{ error.msg }}
            </p>
            <!-- <p v-else class="text-red-500 font-medium text-md w-full h-6"></p> -->
            <!-- <div>
       Select scheme

       <select name="scheme" id="scheme" v-model="scheme">
        <option value="Bayelsa">Bayelsa</option>
        <option value="Rivers">Rivers state</option>
       </select>
      </div> -->
            <div class="w-full space-y-1 mb-2.5">
              <label for="scheme" class="text-gray-600 font-semibold"
                >State</label
              >
              <input
                type="text"
                name="scheme"
                v-model="scheme"
                autocomplete="off"
                placeholder="Please enter state"
                class="hidden h-12 md:h-10 w-full px-4 border-2 text-gray-700 font-medium border-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 focus:shadow-md"
              />

              <NigerianStates @select-state="selectState" />
            </div>
            <div class="w-full space-y-1 mb-2.5">
              <label for="username" class="text-gray-600 font-semibold"
                >Username</label
              >
              <input
                type="text"
                name="username"
                v-model="username"
                autocomplete="off"
                placeholder="Please enter username"
                class="h-12 md:h-10 w-full px-4 border-2 text-gray-700 font-medium border-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 focus:shadow-md"
              />
            </div>
            <div class="w-full space-y-1 mb-6">
              <label for="password" class="text-gray-600 font-semibold"
                >Password</label
              >
              <input
                :type="showPassword ? 'text' : 'password'"
                name="password"
                v-model="password"
                autocomplete="off"
                @keyup.enter="loginUser"
                placeholder="Please enter Password"
                class="h-12 md:h-10 w-full px-4 border-2 text-gray-700 font-medium border-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 focus:shadow-md"
              />
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="showpassword"
                    @click="showPassword = !showPassword"
                    class="focus:ring cursor-pointer rounded-lg"
                  />
                  <span class="text-gray-600 font-medium rounded"
                    >Show password</span
                  >
                </div>
                <a
                  class="focus:outline-none cursor-pointer text-green-primary-dark text-sm font-semibold tracking-tight underline"
                  @click.prevent="showResetPassword"
                >
                  Reset password?
                </a>
              </div>
            </div>
            <button
              id="btnLogin"
              type="button"
              @click.prevent="loginUser"
              :disabled="loggingIn"
              class="px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
            >
              <div v-if="!loggingIn">Login</div>
              <div v-else class="flex items-center space-x-4 justify-center">
                <i class="fas fa-circle-notch animate-spin"></i>
                <span>Please wait...</span>
              </div>
            </button>
          </div>
        </form>
        <form name="resetPassword" v-else action="/resetPassword">
          <div class="form-input flex flex-col w-full md:pt-10">
            <p
              v-if="error.state"
              class="transition text-red-500 font-medium text-sm w-full mb-2 bg-red-200 px-4 py-2 rounded-md"
            >
              {{ error.msg }}
            </p>

            <div class="w-full space-y-1 mb-8">
              <label for="email" class="text-gray-600 font-semibold"
                >Email Address</label
              >
              <input
                type="email"
                name="email"
                v-model="resetPasswordEmail"
                autocomplete="off"
                placeholder="Please enter email"
                class="h-12 md:h-10 w-full px-4 border-2 text-gray-700 font-medium border-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 focus:shadow-md"
              />
            </div>

            <button
              id="btnLogin"
              type="button"
              @click.prevent="sendPasswordResetLink"
              :disabled="loggingIn"
              class="px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
            >
              <div v-if="!loggingIn">Send reset link</div>
              <div v-else class="flex items-center space-x-4 justify-center">
                <i class="fas fa-circle-notch animate-spin"></i>
                <span>Please wait...</span>
              </div>
            </button>
            <button
              class="focus:outline-none mt-8 text-center w-full text-gray-500 font-medium text-md hover:text-gray-600 tracking-wide"
              @click="resetPasswordState = error.state = false"
            >
              Back to login
            </button>
          </div>
        </form>
        <img
          src="../assets/logo.png"
          alt="RevenueHouse"
          :class="`${
            loggingIn ? 'animate-bounce' : ''
          } mt-6 w-16 h-16 mx-auto shadow-sm opacity-80`"
        />
      </div>
      <p
        class="flex-none w-full text-sm text-gray-600 sm:text-gray-600 font-medium mt-4 text-center"
      >
        CopyRight © {{ year }}, all rights reserved
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NigerianStates from "../components/shared/NigerianStates.vue";

export default {
  components: {
    NigerianStates,
  },
  data: () => ({
    loggingIn: false,
    showPassword: false,
    username: "",
    password: "",
    resetPasswordState: false,
    resetPasswordEmail: "",
    scheme: "",
    timeout: null,
    error: {
      state: false,
      msg: "It seems an error occurred, please try again...",
    },
  }),
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions(["login", "resetPassword"]),
    selectState(state) {
      this.scheme = state;
      localStorage.setItem("RH_scheme", state);
    },
    showError(msg) {
      clearTimeout(this.timeout);
      this.error.state = true;

      if (msg.toString().includes("timeout"))
        this.error.msg =
          "Sorry, you seem to be on a slow network. Kindly check your network and try again.";
      else this.error.msg = msg;

      this.timeout = setTimeout(() => {
        this.error.state = false;
      }, 10000);
    },

    loginUser() {
      if (this.username !== "" && this.password !== "" && this.scheme !== "") {
        this.loggingIn = true;

        this.login({
          username: this.username,
          password: this.password,
          // scheme: this.scheme,
        })
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            //   console.log(res);
            // window.location.reload();
            // this.redirectUser();

            this.$router.replace("/");
            this.loggingIn = false;
          })
          .catch((err) => {
            console.log(err);
            this.showError(err);

            this.loggingIn = false;
          })
          .then(() => (this.loggingIn = false));
      } else {
        this.showError(
          "You know we can't log you in without a state, username and password!!!"
        );
      }
    },
    showResetPassword() {
      // console.log("resetting user password");
      this.resetPasswordState = true;
      this.error.state = false;
    },
    sendPasswordResetLink() {
      // console.log("sending password reset link...");
      if (this.resetPasswordEmail !== "") {
        this.loggingIn = true;

        this.resetPassword({
          username: this.username,
          password: this.password,
          scheme: this.scheme,
        })
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            //   console.log(res);
            this.loggingIn = false;
          })
          .catch((err) => {
            this.showError(err);
            this.loggingIn = false;
          })
          .then(() => (this.loggingIn = false));
      } else {
        this.showError(
          "you want us to reset your password without an email ?!!!"
        );
      }
    },
  },
  created() {
    this.scheme = localStorage.getItem("RH_scheme") || "";
  },
};
</script>
