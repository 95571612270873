<template>
  <content-holder
    :title="'Generate Requests'"
    class="main-h-2 overflow-y-scroll"
  >
    <div class="bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <alert-banner
        v-if="alert.state"
        :alert="alert"
        @close="alert.state = false"
      ></alert-banner>
      <div class="w-full sm:flex items-end sm:space-x-4">
        <div class="relative mb-4 sm:mb-0">
          <label for="name" class="leading-7 text-sm text-gray-600">From</label>
          <input
            type="number"
            id="year1"
            name="year1"
            min="1990"
            :max="currentYear"
            v-model="yearRange.first"
            class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
        </div>
        <div class="relative mb-4 sm:mb-0">
          <label for="name" class="leading-7 text-sm text-gray-600">To</label>
          <input
            type="number"
            id="year2"
            name="year2"
            min="1990"
            :max="currentYear"
            v-model="yearRange.second"
            class="w-full bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
        </div>

        <button
          @click="generateRequests"
          type="button"
          :disabled="newLoading"
          class="px-8 py-2 w-full sm:w-auto mb-4 sm:mb-0 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-md bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
        >
          <div v-if="!newLoading">Load data</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
        <button
          v-if="requestsSelected"
          @click="sendRequests"
          type="button"
          :disabled="sendRequestLoading"
          class="px-8 py-2 w-full sm:w-auto md:ml-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg shadow-md bg-blue-primary hover:bg-blue-primary-dark focus:ring focus:ring-green-700 focus:outline-none"
        >
          <div v-if="!sendRequestLoading">Send requests</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>
      <!-- <p class="text-xs text-gray-500 mt-3">
     Chicharrones blog helvetica normcore iceland tousled brook viral artisan.
    </p> -->
    </div>
    <div>
      <input
        type="text"
        name="taxpayer"
        v-model="taxpayer"
        placeholder="Enter tax company name to filter"
        class="w-full mt-10 sm:mt-0 h-12 px-4 mb-2 border-2 text-gray-700 font-medium border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-600"
      />
    </div>
    <div class="mx-0 sm:mx-0 overflow-x-auto mt-6 sm:mt-0">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                >
                  <span class="sr-only">Select</span>
                  <input
                    @change="selectAll"
                    v-model="allRowsSelected"
                    type="checkbox"
                    class="focus:ring cursor-pointer rounded-lg"
                  />
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Company name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Address
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Year
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="company in auditCompanies" :key="company.company_id">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm font-medium text-gray-900">
                    <input
                      type="checkbox"
                      :checked="company.selected"
                      @change="selectCompany(company)"
                      class="focus:ring cursor-pointer rounded-lg"
                    />
                  </div>
                </td>
                <td class="px-6 py-4 break-normal">
                  <div
                    class="text-sm w-80 break-normal font-medium text-gray-900"
                  >
                    {{ company.company_name }}
                  </div>
                </td>
                <td class="px-6 py-4 break-normal">
                  <div class="text-sm text-gray-900 w-80">
                    {{ company.company_address }}
                  </div>
                </td>
                <td class="px-6 py-4 break-normal">
                  <div class="text-sm text-gray-900 w-80">
                    {{ company.year_range }}
                  </div>
                </td>
              </tr>

              <!-- More items... -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </content-holder>
</template>

<script>
import ContentHolder from "@/components/ContentHolder.vue";
import { auditMixin } from "@/mixins/audit.js";
import AlertBanner from "@/components/AlertBanner.vue";
import Swal from "sweetalert2";
export default {
  components: { ContentHolder, AlertBanner },
  mixins: [auditMixin],
  data: () => ({
    taxpayer: "",
    yearRange: { first: "", second: "" },
    payer: {
      name: "",
      address: "",
    },
    payers: [],
    companies: [],
    allRowsSelected: false,
    requestsSelected: false,
    newLoading: false,
    sendRequestLoading: false,
    dataLoaded: false,
    editLoading: false,
    print: false,
    auditDetails: "",
  }),
  computed: {
    auditCompanies() {
      //  console.log(this.getAuditCompanies);
      if (this.companies)
        this.companies.forEach((ac) => {
          ac.year_range = this.yearRange.first + " - " + this.yearRange.second;

          if (ac.selected) ac.selected = true;
          else ac.selected = false;
        });
      return this.companies.filter((comp) => {
        return comp.company_name
          .toLowerCase()
          .includes(this.taxpayer.toLowerCase());
      });
    },
    // companies() {
    //   if (this.getCompanies)
    //     return this.getCompanies.filter((comp) => {
    //       return comp.company_name.toLowerCase().includes(this.taxpayer.toLowerCase());
    //     });
    //   else return [];
    // },
  },
  methods: {
    selectCompany(company) {
      // let adc = this.companies;
      // company.selected = !company.selected;

      this.requestsSelected = false;
      this.companies.forEach((pr) => {
        if (company.id === pr.id) pr.selected = !pr.selected;
        if (pr.selected) this.requestsSelected = true;
      });
    },
    selectAll() {
      // let adc = this.companies;

      if (this.allRowsSelected) {
        this.companies.forEach((ac) => {
          ac.selected = true;
        });
        this.requestsSelected = true;
      } else {
        this.companies.forEach((ac) => {
          ac.selected = false;
        });
        this.requestsSelected = false;
      }

      // this.alterAuditCompanies(adc);
    },
    editDetails(details) {
      this.payer.name = details.company_name;
      this.payer.address = details.company_address;
    },
    generateRequests() {
      this.newLoading = true;
      this.alterAuditCompanies([]);
      // this.getCompaniesListForAudit({
      //   limit: 6000,
      //   startNum: 0,
      // })
      this.getCompaniesListForAudit()
        .then((data) => {
          this.companies = data;
          if (this.companies) {
            this.allRowsSelected = false;
            this.dataLoaded = true;
          } else {
            this.showError("No company data found...");
          }
          this.newLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.newLoading = false;
        });
    },
    sendRequests() {
      this.sendRequestLoading = true;

      let preparedRequests = [];
      this.companies.forEach((pr) => {
        if (pr.selected) {
          preparedRequests.push(pr);
        }
      });
      let finalRequests = preparedRequests.map((pr) => {
        return {
          company_address: pr.company_address,
          company_name: pr.company_name,
          company_id: pr.company_id,
          receiver: pr.receiver,
          year_range: pr.year_range,
        };
      });

      this.sendAuditRequests({ companies: finalRequests })
        .then((msg) => {
          this.sendRequestLoading = false;
          this.allRowsSelected = false;
          this.dataLoaded = false;
          this.requestsSelected = false;
          this.taxpayer = "";
          Swal.fire(msg, "", "success");
          this.companies = [];
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(err, "", "error");
          this.sendRequestLoading = false;
        });
    },
    deleteRecord() {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        // console.log(details);
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "", "success");
        }
      });
    },
  },
  mounted() {
    this.yearRange.first = new Date().getFullYear() - 1;
    this.yearRange.second = new Date().getFullYear();
    if (this.auditCompanies.length > 0) this.allRowsSelected = true;
  },
  beforeUnmount() {
    this.alterAuditCompanies([]);
  },
};
</script>

<style></style>
