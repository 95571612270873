import { cookies } from "@/helpers/cookie";
import { STORAGE_ITEM_NAME } from "@/helpers/keys";

let user, username, designation, unit, role, mda;
const allViews = [
  "Tcc",
  "Payments",
  "Audits",
  "Tpdb",
  "Assessment",
  "Reports",
  "PayerDetails",
  "StampDuty",
  "PropertyDetails",
  "ManageReceipts",
  "AnnualReturns",
];
let views = [];

function getCookieValues() {
  user = cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.name
    ? cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.name.toLowerCase()
    : "";
  username = cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.username
    ? cookies
        .getJSONCookie(STORAGE_ITEM_NAME)
        .loginDetails.username.toLowerCase()
    : "";
  designation = cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails
    .designation
    ? cookies
        .getJSONCookie(STORAGE_ITEM_NAME)
        .loginDetails.designation.toLowerCase()
    : "";
  unit = cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.unit
    ? cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.unit.toLowerCase()
    : "";
  role = cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.role
    ? cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.role.toLowerCase()
    : "";

  mda = cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.mda
    ? cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.mda.id
      ? cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.mda.id
      : cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.mda
    : "";

  if (!cookies.getJSONCookie(STORAGE_ITEM_NAME).loginDetails.mda.id) {
    cookies.setCookie(
      STORAGE_ITEM_NAME,
      JSON.stringify({ status: "false", data: {} }),
      365
    );
    window.location.reload();
  }
}

function loadDetails() {
  getCookieValues();

  //For executive chairman
  if (unit === "chairman") {
    // delete allViews[8];
    views = allViews;
  }

  //links for assessment
  if (unit === "assessment" && role === "director") {
    views = [
      allViews[1],
      allViews[3],
      allViews[4],
      allViews[5],
      allViews[6],
      allViews[10],
    ];
  } else if (unit === "assessment" && role === "basic") {
    views = [allViews[3], allViews[4], allViews[6], allViews[10]];
  } else if (unit === "assessment" && role === "unithead") {
    views = [allViews[3], allViews[4], allViews[6], allViews[10]];
  }

  //links for compliance
  if (unit === "compliance" && (role === "director" || role === "unithead")) {
    views = [allViews[1], allViews[2], allViews[3], allViews[6]];
  } else if (unit === "compliance" && role === "basic") {
    views = [];
  }

  //links for collections
  if (unit === "collections" && (role === "director" || role === "unithead")) {
    views = [
      allViews[0],
      allViews[1],
      allViews[3],
      allViews[5],
      allViews[6],
      allViews[9],
    ];
  } else if (unit === "collections" && role === "basic") {
    views = [];
  }

  //links for stamp duty
  if (unit === "stampduty" && (role === "director" || role === "unithead")) {
    views = [allViews[7]];
  } else if (unit === "stampduty" && role === "basic") {
    views = [allViews[7]];
  }

  //links for perm se
  if (
    unit === "permanent secretary" &&
    (role === "director" || role === "unithead")
  ) {
    views = [allViews[1], allViews[3]];
  } else if (unit === "permanent secretary" && role === "basic") {
    views = [];
  }

  //Finance
  if (unit === "finance" && role === "director") {
    views = [allViews[1], allViews[3], allViews[5], allViews[7]];
  } else if (unit === "finance" && role === "unithead") {
    views = [allViews[1], allViews[3], allViews[7]];
  }

  //Treasury
  if (unit === "treasury") {
    views = [allViews[1], allViews[8]];
  }

  //links for technical adviser
  if (unit == "technical adviser" && role == "technicaladviser") {
    views = [allViews[1], allViews[2], allViews[3], allViews[6], allViews[9]];
  } else if (unit == "technical adviser" && role == "basic") {
    views = [allViews[2], allViews[9]];
  }

  //chairman's office
  if (unit == "chairman" && role == "director") {
    views = allViews;
  }
  if (unit == "chairman" && role == "basic") {
    views = [allViews[2]];
  }

  //administrators
  if (unit === "administrator") {
    views = allViews;
  }

  //AG
  if (unit === "accounts" && role == "director" && mda != 1) {
    views = [allViews[1], allViews[3], allViews[5]];
  } else if (unit === "accounts" && role == "unithead") {
    views = [allViews[1], allViews[3]];
  }

  //Accounts board
  if (unit === "accounts" && role == "unithead" && mda == 1) {
    views = [allViews[1], allViews[3], allViews[5], allViews[9]];
  } else if (unit === "accounts" && role == "basic" && mda == 1) {
    views = [allViews[3], allViews[9]];
  }

  //Other Mdas
  if (unit === "other_mda") {
    views = [allViews[1], allViews[3], allViews[8]];
  }

  //Viewers
  if (unit === "v" && role == "viewer") {
    views = [allViews[8]];
  }

  // console.log(unit, role, user, views);
}

// setInterval(() => {
//     loadDetails();
// }, 1000);

export { user, username, designation, unit, role, views, loadDetails, mda };
