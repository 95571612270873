<template>
  <div class="w-full">
    <div class="relative mt-1">
      <button
        @click.prevent="showList = !showList"
        type="button"
        class="relative w-full h-12 md:h-10 pl-0 pr-10 text-left border-2 text-gray-700 font-medium border-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 focus:shadow-md"
        :class="customBtnClass"
      >
        <span class="flex items-center">
          <span class="block ml-3 truncate"> {{ selectedState }} </span>
        </span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
        >
          <svg
            class="w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </span>
      </button>
      <div
        v-if="showList"
        class="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <li
            v-for="(state, index) in states"
            :key="index"
            id="listbox-item-0"
            role="option"
            @click.prevent="selectState(state)"
            class="relative py-2 pl-0 text-gray-900 cursor-pointer select-none hover:bg-blue-500 hover:text-white pr-9"
          >
            <div class="flex items-center">
              <span class="block ml-3 font-normal truncate"> {{ state }} </span>
            </div>
            <span class="absolute inset-y-0 right-0 flex items-center pr-4">
              <svg
                class="w-5 h-5 hidden"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { states } from "../../data/nigerian_states.js";
export default {
  props: ["customBtnClass"],
  emits: ["select-state"],
  data() {
    return {
      states,
      selectedState: states[5],
      showList: false,
    };
  },
  methods: {
    selectState(state) {
      this.selectedState = state;
      this.showList = false;
      this.$emit("select-state", state);
    },
  },
  mounted() {
    this.$emit("select-state", this.selectedState);
  },
};
</script>

<style lang="scss" scoped></style>
