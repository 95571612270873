<template>
  <div class="single">
    <teleport to=".modal">
      <authorize-modal
        title="Tax Payer Information"
        v-show="showTaxPayerImage"
        @hideModal="showTaxPayerImage = false"
      >
        <div
          class="w-full flex flex-row items-center justify-center overflow-auto pr-0 sm:pr-6"
        >
          <img
            @click.stop="showImage"
            v-if="requestDetails"
            class="w-full h-full sm:w-64 sm:h-64"
            :src="`https://tcc.mekiotechdigital.com/api/tcc/uploads/${requestDetails.tpImage}`"
            alt="Tax Payer"
            srcset=""
          />
        </div>
      </authorize-modal>
    </teleport>
    <teleport to=".modal">
      <authorize-modal
        title="Tax Payer Information"
        v-show="showPaymentDetailsModal"
        @hideModal="showPaymentDetailsModal = false"
      >
        <div
          class="sm:block hidden w-full overflow-x-hidden overflow-y-auto pr-0 sm:pr-6"
        >
          <h1
            class="text-sm text-left mb-2 text-gray-700 font-medium flex-wrap"
          >
            {{ requestDetails.address }}
          </h1>
          <table class="table p-4 bg-white shadow rounded-lg">
            <thead>
              <tr class="">
                <th
                  class="text-left border p-4 dark:border-dark-5 whitespace-nowrap bg-gray-100 font-semibold text-gray-700"
                >
                  Year
                </th>
                <th
                  class="text-left border p-4 dark:border-dark-5 whitespace-nowrap bg-gray-100 font-semibold text-gray-700"
                >
                  Income
                </th>
                <th
                  class="text-left border p-4 dark:border-dark-5 whitespace-nowrap bg-gray-100 font-semibold text-gray-700"
                >
                  Tax Paid
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year1 }}
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.total_income_year1) }}
                  </div>
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.taxpayable_year1) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year2 }}
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.total_income_year2) }}
                  </div>
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.taxpayable_year2) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year3 }}
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.total_income_year3) }}
                  </div>
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.taxpayable_year3) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 font-semibold bg-gray-100 text-left">
                <td class="text-left border p-4 dark:border-dark-5">Total</td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left w-40">
                    {{ totalIncome(requestDetails) }}
                  </div>
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left w-40">
                    {{ formatAmount(requestDetails.amount) }}
                  </div>
                </td>
              </tr>
              <!-- <tr>
                                <td colspan="4" class="w-full py-4 px-4 border-2 border-gray-200 bg-gray-100 ">
                                    <div class="flex flex-row space-x-10">
                                        <div
                                            class=" flex flex-row items-center text-left text-md leading-5 text-gray-700 font-semibold">
                                            {{totalIncome(requestDetails)}}
                                        </div>
                                        <div
                                            class=" flex flex-row items-center text-left text-md leading-5 text-gray-700 font-semibold">
                                            {{formatAmount(requestDetails.amount)}}
                                        </div>
                                    </div>
                                </td>
                            </tr> -->
            </tbody>
          </table>
        </div>
        <div
          class="sm:hidden block w-full h-96 overflow-x-hidden overflow-y-auto pr-0 sm:pr-6"
        >
          <h1
            class="text-sm text-left mb-2 text-gray-700 font-medium flex-wrap"
          >
            {{ requestDetails.address }}
          </h1>
          <table class="table p-4 w-full bg-white shadow rounded-lg">
            <thead>
              <tr class="">
                <th
                  class="text-left border p-4 dark:border-dark-5 whitespace-nowrap bg-gray-100 font-semibold text-gray-700"
                >
                  Year
                </th>
                <th
                  class="text-left border p-4 dark:border-dark-5 whitespace-nowrap bg-gray-100 font-semibold text-gray-700"
                >
                  Income
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year1 }}
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.total_income_year1) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year2 }}
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.total_income_year2) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year3 }}
                </td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.total_income_year3) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 font-semibold bg-gray-100 text-left">
                <td class="text-left border p-4 dark:border-dark-5">Total</td>
                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left w-40">
                    {{ totalIncome(requestDetails) }}
                  </div>
                </td>
              </tr>
              <!-- <tr>
                                <td colspan="4" class="w-full py-4 px-4 border-2 border-gray-200 bg-gray-100 ">
                                    <div class="flex flex-row space-x-10">
                                        <div
                                            class=" flex flex-row items-center text-left text-md leading-5 text-gray-700 font-semibold">
                                            {{totalIncome(requestDetails)}}
                                        </div>
                                        <div
                                            class=" flex flex-row items-center text-left text-md leading-5 text-gray-700 font-semibold">
                                            {{formatAmount(requestDetails.amount)}}
                                        </div>
                                    </div>
                                </td>
                            </tr> -->
            </tbody>
          </table>
          <table class="table p-4 w-full bg-white shadow rounded-lg mt-6">
            <thead>
              <tr class="">
                <th
                  class="text-left border p-4 dark:border-dark-5 whitespace-nowrap bg-gray-100 font-semibold text-gray-700"
                >
                  Year
                </th>

                <th
                  class="text-left border p-4 dark:border-dark-5 whitespace-nowrap bg-gray-100 font-semibold text-gray-700"
                >
                  Tax Paid
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year1 }}
                </td>

                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.taxpayable_year1) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year2 }}
                </td>

                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.taxpayable_year2) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 text-left text-sm">
                <td class="text-left border p-4 dark:border-dark-5">
                  {{ requestDetails.year3 }}
                </td>

                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left sm:w-40">
                    {{ formatAmount(requestDetails.taxpayable_year3) }}
                  </div>
                </td>
              </tr>
              <tr class="text-gray-700 font-semibold bg-gray-100 text-left">
                <td class="text-left border p-4 dark:border-dark-5">Total</td>

                <td class="border p-4 dark:border-dark-5">
                  <div class="text-left w-40">
                    {{ formatAmount(requestDetails.amount) }}
                  </div>
                </td>
              </tr>
              <!-- <tr>
                                <td colspan="4" class="w-full py-4 px-4 border-2 border-gray-200 bg-gray-100 ">
                                    <div class="flex flex-row space-x-10">
                                        <div
                                            class=" flex flex-row items-center text-left text-md leading-5 text-gray-700 font-semibold">
                                            {{totalIncome(requestDetails)}}
                                        </div>
                                        <div
                                            class=" flex flex-row items-center text-left text-md leading-5 text-gray-700 font-semibold">
                                            {{formatAmount(requestDetails.amount)}}
                                        </div>
                                    </div>
                                </td>
                            </tr> -->
            </tbody>
          </table>
        </div>
      </authorize-modal>
    </teleport>
    <div
      v-if="companyRequestSingles.length > 0"
      class="sticky sm:w-full px-4 pb-2 my-4 lg:mt-8 lg:mb-4"
    >
      <input
        type="text"
        name="taxpayer"
        v-model="taxpayer"
        placeholder="Enter tax payer's name to filter"
        class="w-full h-12 px-4 mt-2 border-2 text-gray-700 font-medium border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-600"
      />
    </div>

    <div
      v-if="
        (unit == 'chairman' || unit == 'administrator') &&
        companyRequestSingles.length > 0
      "
      class="w-full hidden mb-4 text-right"
    >
      <div class="flex flex-row items-center space-x-4 sm:w-80 px-4">
        <button
          :disabled="authorizing"
          @click="authorizeAll"
          class="px-4 py-1 mt-6 text-xs w-full inline-flex items-center justify-center rounded-md bg-green-primary-dark text-white shadow-md focus:outline-none ring-2 ring-green-600 font-semibold"
        >
          <div v-if="!authorizing">Authorize all</div>
          <div v-else class="flex items-center justify-center">
            <i class="hidden fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
        <button
          :disabled="declining"
          @click="declineAll"
          class="px-4 py-1 mt-6 text-xs w-full inline-flex items-center justify-center rounded-md bg-red-700 text-white shadow-md focus:outline-none ring-2 ring-red-600 font-semibold"
        >
          <div v-if="!declining">Decline all</div>
          <div v-else class="flex items-center justify-center">
            <i class="hidden fas fa-circle-notch animate-spin"></i>
            <span class="">Please wait...</span>
          </div>
        </button>
      </div>
    </div>

    <div
      v-if="companyRequestSingles.length > 0"
      class="flex flex-col md:flex-row md:flex-wrap w-full overflow-x-hidden pb-20 mt-4 md:mt-0"
    >
      <div
        v-for="request in companyRequestSingles"
        :key="request.db_certificate_id"
        class="w-full md:w-1/2 lg:w-1/3 xl:w-reqXl 2xl:w-req2xl p-3 max-h-72"
      >
        <request-card
          :id="request.db_certificate_id"
          :title="request.taxpayer"
          :total="request.amount"
          :year="request.request_year"
          :phone="request.phone_number"
          :isGroup="false"
          :request="request"
        >
        </request-card>
      </div>
      <!-- <div
        v-for="n in 20"
        :key="n"
        class="w-full md:w-1/2 lg:w-1/3 xl:w-reqXl 2xl:w-req2xl p-3 max-h-72"
      >
        <request-card
          :id="n"
          :title="'Immanuel Dickson'"
          :total="'2000,00000,000.00'"
          :year="'2018'"
          :phone="'07037814283'"
          :isGroup="false"
          :request="{}"
        >
        </request-card>
      </div> -->
    </div>
    <div class="w-full text-center pt-20" v-else>
      <i
        :class="`lg:focus:shadow-sm w-12 text-center fas fa-circle-notch mt-20 text-4xl text-blue-primary animate-spin`"
      ></i>
    </div>
  </div>
</template>

<script>
import emitter from "tiny-emitter/instance";
import { requestMixin } from "@/mixins/requests";
import AuthorizeModal from "@/components/AuthorizeModal.vue";
import numeral from "numeral";
import { unit } from "../../helpers/login_details";
import Swal from "sweetalert2";

export default {
  mixins: [requestMixin],
  components: { AuthorizeModal },
  data: () => ({
    taxpayer: "",
    showPaymentDetailsModal: false,
    showTaxPayerImage: false,
    requestDetails: {},
    authorizing: false,
    declining: false,
    title: "",
    year: "",
    unit,
  }),
  methods: {
    formatAmount(amt) {
      return "₦ " + numeral(amt).format("0,0.00");
    },
    totalIncome(request) {
      return this.formatAmount(
        request.total_income_year1 +
          request.total_income_year2 +
          request.total_income_year3
      );
    },
    authorizeAll() {
      this.authorizing = true;
      this.$store
        .dispatch("authorizeAll", {
          orgName: this.title,
          year: this.year,
          status: "authorize",
        })
        .then(() => {
          this.$store.dispatch("getRequestYears");
        })
        .then(() => {
          this.$store.dispatch("reduceRequests", {
            title: this.title,
            year: this.year,
          });
          this.authorizing = false;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.authorizing = false;
        });
    },
    declineAll() {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, decline it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.declining = true;
          this.$store
            .dispatch("authorizeAll", {
              orgName: this.title,
              year: this.year,
              status: "decline",
            })
            .then(() => {
              this.$store.dispatch("getRequestYears");
            })
            .then(() => {
              this.$store.dispatch("reduceRequests", {
                title: this.title,
                year: this.year,
              });
              this.declining = false;
            })
            .catch((err) => {
              this.handleError(err);
            })
            .finally(() => {
              this.declining = false;
            });
          // Swal.fire("Poof! Your imaginary file has been deleted!", {
          //   icon: "success",
          // });
        }
      });
    },
  },
  computed: {
    companyRequestSingles() {
      return this.$store.getters.getCompanyRequestSingles.filter((req) => {
        return req.taxpayer.toLowerCase().includes(this.taxpayer.toLowerCase());
      });
    },
  },
  mounted() {
    //
    emitter.on("showBreakdown", (request) => {
      this.showPaymentDetailsModal = true;
      this.requestDetails = request;
    });
    emitter.on("showImage", (request) => {
      this.showTaxPayerImage = true;
      this.requestDetails = request;
    });
    emitter.on("showAll", ({ org, year }) => {
      this.title = org;
      this.year = year;
    });
  },
};
</script>

<style></style>
